@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
.overAllContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-bottom: 10vh;
    align-items: center;
}
.subContainer {
    margin: 25px 0;
}
.hello {
    margin: 0;
    font-size: 2rem;
    font-family: 'Baloo Chettan 2', sans-serif;
    font-style: normal;
    font-weight: normal;
}
.name {
    margin: 0;
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4.5rem;
}
.who {
    margin: 0;
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4.5rem;
}
.whatDo {
    margin: 0;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
}
.contactButton {
    all: unset;
    font-family: 'Yeseva One', sans-serif;
    margin-top: 25px;
    border: white solid 2px;
    padding: 10px 20px;
    border-radius: 1000px;
    transition: box-shadow 250ms;
}
.contactButton:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 10px #4997ff;
}
.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    z-index: -100;
    object-fit: cover;
    filter: blur(2px);
    overflow: hidden;
}
.gearContainer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left:-12.5vw;
    width: 125vw;
    bottom: 37.5vh;

}
.gear {
    width: 25vw;
    position: relative;
}
.leftGear {
    animation: rotation infinite 20s linear;
}
.rightGear {
    animation: rotation infinite 20s linear;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@media (min-width: 512px) and  (max-height: 512px){
    .hello {
        font-size: 1rem;
    }
    .name {
        font-size: 2.5rem;
    }
    .who {
        font-size: 2.5rem;
    }
    .whatDo {
        font-size: 1rem;
    }
}
@media (max-width: 512px) {
    .overAllContainer {
        align-items: flex-start;
        text-align: start;
        padding-left: 20px;
    }
    .hello {
        font-size: 1rem;
    }
    .name {
        font-size: 2rem;
    }
    .who {
        font-size: 2rem;
    }
    .whatDo {
        font-size: 1rem;
    }
    .gearContainer {
        left:-25vw;
        width: 150vw;
        bottom: 37.5vh;
        opacity: 0.5;
    }
    .gear {
        width: 50vw;
    }
}