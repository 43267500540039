.overAllContainer {
    position: absolute;
    top:0;
    left:-50vw;
    transition: left 250ms;
}
.modal {
    position: absolute;
    background-color: black;
    top:0;
    left:-50vw;
    width: 100vw;
    height: 100vh;
    opacity: 50%;
}
.open {
    left:0;
}
.sideMenuContainer {
    position: absolute;
    width: 50vw;
    height: 100vh;
    top:0;
    text-align: left;
    background-color: #1e1e1e;
    padding-left:30px ;
}
.menuText {
    font-size: 20px;
    font-family: "Balsamiq Sans",serif;
    font-weight: bold;
    margin-top: 30px;

}
.menu {
    height: 20vh;
    font-weight: 400;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    padding: 0;
    margin: 5vh 0;
}

.menuItem {
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: font-size,font-weight,color 250ms;
}
.menuItem:hover {
    color: #0095ff;
    font-size: 1.2rem;
    font-weight: 900;
}
.activeItem {
    color: #fbbd61;
    font-size: 1.2rem;
    font-weight: 900;
}
.cv {
    padding: 3px 6px;
    border: 2px white solid;
    color: white;
    background-color: black ;
    border-radius: 1000px;
    cursor: pointer;
}
@media (min-width: 768px) {
    .overAllContainer {
        left:-50vw;
    }
    .modal {
        left:-50vw;
    }
}