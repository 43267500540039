.navBar {
    display: flex;
    flex-direction: row;
    margin: 0 25vw;
    width: 50vw;
    justify-content: space-around;
    align-items: center;
    max-height: 10vh;
}
.brand {
    all: unset;
    cursor: pointer;
    font-family: "Balsamiq Sans", serif;
    font-weight: bold;
    background: linear-gradient(93.58deg, #FFBA52 0%, #FF67B0 50%, #6A6AFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.menu {
    font-weight: 400;
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    flex-grow: 1;
    justify-content: space-around;
}

.menuItem {
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: font-size,font-weight,color 250ms;
}
.menuItem:hover {
    color: #0095ff;
    font-size: 1.2rem;
    font-weight: 900;
}
.activeItem {
    color: #fbbd61;
    font-size: 1.2rem;
    font-weight: 900;
}
.cv {
    height: 75%;
    padding: 3px 6px;
    border: 2px white solid;
    color: white;
    background-color: black ;
    border-radius: 1000px;
    cursor: pointer;
}
.sideMenu {
    display:unset;
}
.burgerContainer {
    display: none;
}
.burgerLine {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

@media (min-width: 220px) and (max-width: 768px) {
    .navBar {
        width: 100vw;
        margin: 30px 0 0 0;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
    }
    .burgerContainer {
        display: unset;
    }
    .brand {
        margin-right: 30px;
        font-size: 20px;
    }
    .menu {
        display: none;
    }
    .cv {
        display: none;
    }
    .aClass {
        display: none;
    }
}