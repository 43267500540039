@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap);
* {
  box-sizing: border-box;
}

html {
  background: black;
}
body {
  margin: 0;
  color: white;
  font-family: Ubuntu,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
}

.App_App__1PQm2 {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App_mainContent__tw2MD {
  flex-grow: 1;
  width: 100vw;
  max-height: 90vh;
  align-items: center;
}


.App_App-header__wTEYV {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App_App-link__1Itjb {
  color: #61dafb;
}


.TopMenu_navBar__3tVfZ {
    display: flex;
    flex-direction: row;
    margin: 0 25vw;
    width: 50vw;
    justify-content: space-around;
    align-items: center;
    max-height: 10vh;
}
.TopMenu_brand__2fNJY {
    all: unset;
    cursor: pointer;
    font-family: "Balsamiq Sans", serif;
    font-weight: bold;
    background: linear-gradient(93.58deg, #FFBA52 0%, #FF67B0 50%, #6A6AFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.TopMenu_menu__wVs_7 {
    font-weight: 400;
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    flex-grow: 1;
    justify-content: space-around;
}

.TopMenu_menuItem__poUBy {
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: font-size,font-weight,color 250ms;
}
.TopMenu_menuItem__poUBy:hover {
    color: #0095ff;
    font-size: 1.2rem;
    font-weight: 900;
}
.TopMenu_activeItem__BHyTv {
    color: #fbbd61;
    font-size: 1.2rem;
    font-weight: 900;
}
.TopMenu_cv__lwbPW {
    height: 75%;
    padding: 3px 6px;
    border: 2px white solid;
    color: white;
    background-color: black ;
    border-radius: 1000px;
    cursor: pointer;
}
.TopMenu_sideMenu__2STYB {
    display:unset;
}
.TopMenu_burgerContainer__23O9X {
    display: none;
}
.TopMenu_burgerLine__129tw {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

@media (min-width: 220px) and (max-width: 768px) {
    .TopMenu_navBar__3tVfZ {
        width: 100vw;
        margin: 30px 0 0 0;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
    }
    .TopMenu_burgerContainer__23O9X {
        display: unset;
    }
    .TopMenu_brand__2fNJY {
        margin-right: 30px;
        font-size: 20px;
    }
    .TopMenu_menu__wVs_7 {
        display: none;
    }
    .TopMenu_cv__lwbPW {
        display: none;
    }
    .TopMenu_aClass__u9BIm {
        display: none;
    }
}
.SideMenu_overAllContainer__3dnkK {
    position: absolute;
    top:0;
    left:-50vw;
    transition: left 250ms;
}
.SideMenu_modal__3-KT1 {
    position: absolute;
    background-color: black;
    top:0;
    left:-50vw;
    width: 100vw;
    height: 100vh;
    opacity: 50%;
}
.SideMenu_open__1-won {
    left:0;
}
.SideMenu_sideMenuContainer__1eHta {
    position: absolute;
    width: 50vw;
    height: 100vh;
    top:0;
    text-align: left;
    background-color: #1e1e1e;
    padding-left:30px ;
}
.SideMenu_menuText__3dCcl {
    font-size: 20px;
    font-family: "Balsamiq Sans",serif;
    font-weight: bold;
    margin-top: 30px;

}
.SideMenu_menu__kjfvC {
    height: 20vh;
    font-weight: 400;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    padding: 0;
    margin: 5vh 0;
}

.SideMenu_menuItem__1tvNe {
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: font-size,font-weight,color 250ms;
}
.SideMenu_menuItem__1tvNe:hover {
    color: #0095ff;
    font-size: 1.2rem;
    font-weight: 900;
}
.SideMenu_activeItem__2jX4O {
    color: #fbbd61;
    font-size: 1.2rem;
    font-weight: 900;
}
.SideMenu_cv__1VOwl {
    padding: 3px 6px;
    border: 2px white solid;
    color: white;
    background-color: black ;
    border-radius: 1000px;
    cursor: pointer;
}
@media (min-width: 768px) {
    .SideMenu_overAllContainer__3dnkK {
        left:-50vw;
    }
    .SideMenu_modal__3-KT1 {
        left:-50vw;
    }
}
.Home_overAllContainer__3TDwW {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-bottom: 10vh;
    align-items: center;
}
.Home_subContainer__2P5tP {
    margin: 25px 0;
}
.Home_hello__3mNZ6 {
    margin: 0;
    font-size: 2rem;
    font-family: 'Baloo Chettan 2', sans-serif;
    font-style: normal;
    font-weight: normal;
}
.Home_name__2OLhv {
    margin: 0;
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4.5rem;
}
.Home_who__WFx4g {
    margin: 0;
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4.5rem;
}
.Home_whatDo__2DLrl {
    margin: 0;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
}
.Home_contactButton__1JK3C {
    all: unset;
    font-family: 'Yeseva One', sans-serif;
    margin-top: 25px;
    border: white solid 2px;
    padding: 10px 20px;
    border-radius: 1000px;
    transition: box-shadow 250ms;
}
.Home_contactButton__1JK3C:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 10px #4997ff;
}
.Home_background__tBNRj {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    z-index: -100;
    object-fit: cover;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    overflow: hidden;
}
.Home_gearContainer__3w2VQ {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left:-12.5vw;
    width: 125vw;
    bottom: 37.5vh;

}
.Home_gear__37rV0 {
    width: 25vw;
    position: relative;
}
.Home_leftGear__1nFsQ {
    -webkit-animation: Home_rotation__25ljQ infinite 20s linear;
            animation: Home_rotation__25ljQ infinite 20s linear;
}
.Home_rightGear__g1lz6 {
    -webkit-animation: Home_rotation__25ljQ infinite 20s linear;
            animation: Home_rotation__25ljQ infinite 20s linear;
}
@-webkit-keyframes Home_rotation__25ljQ {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes Home_rotation__25ljQ {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@media (min-width: 512px) and  (max-height: 512px){
    .Home_hello__3mNZ6 {
        font-size: 1rem;
    }
    .Home_name__2OLhv {
        font-size: 2.5rem;
    }
    .Home_who__WFx4g {
        font-size: 2.5rem;
    }
    .Home_whatDo__2DLrl {
        font-size: 1rem;
    }
}
@media (max-width: 512px) {
    .Home_overAllContainer__3TDwW {
        align-items: flex-start;
        text-align: start;
        padding-left: 20px;
    }
    .Home_hello__3mNZ6 {
        font-size: 1rem;
    }
    .Home_name__2OLhv {
        font-size: 2rem;
    }
    .Home_who__WFx4g {
        font-size: 2rem;
    }
    .Home_whatDo__2DLrl {
        font-size: 1rem;
    }
    .Home_gearContainer__3w2VQ {
        left:-25vw;
        width: 150vw;
        bottom: 37.5vh;
        opacity: 0.5;
    }
    .Home_gear__37rV0 {
        width: 50vw;
    }
}
